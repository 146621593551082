.jobQueneRedDiv {
    background-color: rgb(236, 206, 34);
    height: 85px;
    width: 95%;
    margin: 0 auto;
    border-radius: 20px;
    margin-top: 8px;
    color: black;
}

.jobQueneRedDiv:hover {
    background-color: rgb(216, 195, 77);

}

.jobQueneRedDiv .row .col-3 img {
    width: 90px;
}


.selectJobQueneBtn {
    font-size: 19px;
    color: white;
    border: 3px solid rgb(236, 206, 34);
    background: linear-gradient(to top, rgb(236, 206, 34), #00060a 75%);
    font-weight: bold;
    height: 48px;
    padding-top: 9px;
    padding-bottom: 8px;
    border-radius: 15px;
  }